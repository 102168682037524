<template>
  <div class="footer-box">
    <div class="content">
      <div class="top">
        <div class="left">
          <div class="web" style="padding-right: 36px;">{{ site_copyright }}</div>
          <div class="web2" style="padding-right: 156px;margin-top: 9px;">省级工业互联网示范（重点）平台</div>
          <div class="web2" style="padding-right: 228px;margin-top: 19px;">省级服务型制造示范平台</div>
        </div>
        <img class="center" :src="mp">
        <div class="right">
          <div class="web2">潍坊恩源信息科技有限公司</div>
          <div class="web2" style="margin-top: 10px;">单位地址：潍坊市高新区惠贤路新商界总部基地A1</div>
          <div class="web2" style="margin-top: 10px;">联系电话：{{ service_mobile }}</div>
          <div class="web2" style="margin-top: 10px;">单位邮箱：{{ eamil }}</div>
        </div>
      </div>
      <div class="bottom-line"></div>
      <ul class="bottom-link">
        <li class="tab page" @click="pageJump('/home')">首页</li>
        <li>|</li>
        <li class="tab page" @click="pageJump('/news')">新闻资讯</li>
        <li>|</li>
        <li class="tab page" @click="pageJump('/capacitySharing')">产能共享</li>
        <li>|</li>
        <li class="tab page" @click="pageJump('/appStore')">应用商城</li>
        <li>|</li>
        <li class="tab page" @click="pageJump('/solution')">解决方案</li>
        <li>|</li>
        <li class="tab page" @click="pageJump('/IOT')">IOT平台</li>
        <li>|</li>
        <li class="tab page" @click="pageJump('/aboutUs')">关于我们</li>
      </ul>
      <div class="banquan">Copyright © {{ site_copyright }}
        <img src="../assets/img/keep_on_record.png" class="benan_icon">
        <a href="https://beian.miit.gov.cn/" target="_blank">{{ site_beian }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      site_copyright: '',
      site_beian: '',
      service_mobile: '',
      eamil: '',
      mp: '',
      questionList: []
    }
  },
  mounted () {
    this.indexCommonConfig()
    this.indexQuestionList()
  },
  methods: {
    // 
    indexCommonConfig () {
      this.$apiFun.indexCommonConfig().then((res) => {
        if (res.code == 200) {
          this.site_copyright = res.data.site_copyright
          this.site_beian = res.data.site_beian
          this.service_mobile = res.data.service_mobile
          this.eamil = res.data.eamil
          this.mp = res.data.mp
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      })
    },
    // 问题
    indexQuestionList(){
      this.$apiFun.indexQuestionList({page:1,limit:4}).then((res) => {
        if (res.code == 200) {
          this.questionList = res.data.noticeist
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      })
    },
    // 问题详情
    questionClick(id){
      this.$router.push({ path: '/questions', query: {id: id} });
    },
    pageJump (path) {
      this.$router.push({ path: path });
      window.scrollTo({
        top: 0,
        left: 0,
      })
      // let Detail= this.$router.resolve({
      //   path:path,
      // })
      // window.open(Detail.href,'_blank')
      // if (path == 'IOT') {
      //   this.$router.push({ path: '/' });
      //   setTimeout(() => {
      //     window.scrollTo({
      //       top: document.getElementsByClassName("IOT-div")[0].offsetTop,
      //       left: 0,
      //       behavior: "smooth"
      //     })
      //   }, 100);
      // } else {
      //   window.scrollTo({
      //     top: 0,
      //     left: 0,
      //     behavior: "smooth"
      //   })
      //   // window.scrollTo(0, 0)
      //   this.$router.push({ path: path });
      // }
    },
    goAdmin (type) {
      if (type == 1) {
        // 用户注册
        this.$router.push({ path: '/register' });
      } else if (type == 2) {
        // 会员登录
        this.$router.push({ path: '/login' });
      } else if (type == 3) {
        // 找回密码
        this.$router.push({ path: '/password' });
      } else if (type == 4){
        window.scrollTo(0, 0)
        this.$router.push({ path: '/setting', query: {type: 1} });
      } else {
        if (localStorage.getItem('mobile')) {
          this.$apiFun.loginIsLogin({mobile: localStorage.getItem('mobile')}).then((res) => {
            if (res.code == 1) {
              if(type == 5){
                // 产能发布
                window.location.href = 'https://yzh.yuanliancloud.com/admin#/admin/company.companysupply/index.html'
              } else if (type == 6){
                // 产能需求
                window.location.href = 'https://yzh.yuanliancloud.com/admin#/admin/company.companyneed/index.html'
              } else {
                window.location.href = 'https://yzh.yuanliancloud.com/admin'
              }
            } else {
              this.$router.push({ path: '/login' });
            }
          })
        } else {
          this.$router.push({ path: '/login' });
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.footer-box {
  padding: 50px 0 33px 0;
  background-color: #003C7F;
  min-width: 1200px;
  box-sizing: border-box;

  .content {
    margin: 0 auto;
    width: 1200px;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .web2{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
      .left{
        .web{
          font-size: 30px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          
        }
      }
      .center{
        width: 131px;
        object-fit: contain;
      }
      .right{
        .web2{
          padding-left: 36px;
        }
      }
      
    }

    .bottom-line {
      height: 1px;
      background: #A6A9BB;
      margin-top: 9px;
    }

    .bottom-link {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 28px;

      .tab {
        width: 140px;
        text-align: center;
      }

      li {
        color: #FFF;
        opacity: 70%;
      }

      li:hover {
        opacity: 1;
      }
    }
  }

  .tab {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
  }

  .page {
    opacity: 70%;
    cursor: pointer;
  }

  .page:hover {
    opacity: 1;
  }

  .banquan {
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 70%;
    margin-top: 20px;

    .benan_icon {
      position: relative;
      top: 3px;
      margin-right: 5px;
    }
  }
}
</style>
