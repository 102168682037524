import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'
import header from '../components/header.vue' //头部
import footer from '../components/footer.vue' //底部
import store from '../store/index.js'
import { v1 as uuidv4 } from 'uuid'
import apiFun from "../api/api.js";

const routes = [{ //首页
  path: '/',
  redirect: '/home'
}, { //新闻资讯
  path: '/home',
  name: 'home',
  meta: {
    menuIndex: '1',
    keepAlive: true,
  },
  component: () => import('../views/home.vue')
}, { //IOT
  path: '/IOT',
  name: 'IOT',
  meta: {
    menuIndex: '6',
  },
  component: () => import('../views/IOT.vue')
}, { //新闻资讯
  path: '/news',
  name: 'news',
  meta: {
    keepAlive: true,
    menuIndex: '2'
  },
  component: () => import('../views/news.vue')
}, { //资讯详情
  path: '/newsDetail',
  name: 'newsDetail',
  meta: {
    menuIndex: '2'
  },
  component: () => import('../views/newsDetail.vue')
}, { //公告详情
  path: '/noticeInfo',
  name: 'noticeInfo',
  meta: {
    menuIndex: '1'
  },
  component: () => import('../views/noticeInfo.vue')
},{ //问题详情
  path: '/questions',
  name: 'questions',
  meta: {
    menuIndex: '1'
  },
  component: () => import('../views/questions.vue')
}, { //产能共享
  path: '/capacitySharing',
  name: 'capacitySharing',
  meta: {
    keepAlive: true,
    menuIndex: '3-5'
  },
  component: () => import('../views/capacitySharing.vue')
}, { //应用商城
  path: '/appStore',
  name: 'appStore',
  meta: {
    keepAlive: true,
    menuIndex: '4'
  },
  component: () => import('../views/appStore.vue')
}, { //应用商城列表
  path: '/appStoreList',
  name: 'appStoreList',
  meta: {
    keepAlive: true,
    menuIndex: '4'
  },
  component: () => import('../views/appStoreList.vue')
}, { //app询价
  path: '/inquiry',
  name: 'inquiry',
  meta: {
    keepAlive: true,
    menuIndex: '4'
  },
  component: () => import('../views/inquiry.vue')
}, { //供应询价
  path: '/supplyInquiry',
  name: 'supplyInquiry',
  meta: {
    keepAlive: true,
    menuIndex: '3'
  },
  component: () => import('../views/supplyInquiry.vue')
}, { //需求报价
  path: '/demandInquiry',
  name: 'demandInquiry',
  meta: {
    keepAlive: true,
    menuIndex: '3'
  },
  component: () => import('../views/demandInquiry.vue')
}, { //关于我们
  path: '/aboutUs',
  name: 'aboutUs',
  meta: {
    keepAlive: true,
    menuIndex: '7'
  },
  component: () => import('../views/aboutUs.vue')
}, { //解决方案
  path: '/solution',
  name: 'solution',
  meta: {
    keepAlive: true,
    menuIndex: '5'
  },
  component: () => import('../views/solution.vue')
}, { //解决方案详情
  path: '/solutionDetail',
  name: 'solutionDetail',
  meta: {
    menuIndex: '5'
  },
  component: () => import('../views/solutionDetail.vue')
}, { //应用详情
  path: '/appDetail',
  name: 'appDetail',
  meta: {
    menuIndex: '4'
  },
  component: () => import('../views/appDetail.vue')
}, { //供应列表
  path: '/supplyList',
  name: 'supplyList',
  meta: {
    keepAlive: true,
    menuIndex: '3-5'
  },
  component: () => import('../views/supplyList.vue')
}, { //供应详情
  path: '/supplyDetail',
  name: 'supplyDetail',
  meta: {
    menuIndex: '3-5'
  },
  component: () => import('../views/supplyDetail.vue')
}, { //需求列表
  path: '/demandList',
  name: 'demandList',
  meta: {
    keepAlive: true,
    menuIndex: '3-5'
  },
  component: () => import('../views/demandList.vue')
}, { //需求详情
  path: '/demandDetail',
  name: 'demandDetail',
  meta: {
    menuIndex: '3-5'
  },
  component: () => import('../views/demandDetail.vue')
}, { //登录
  path: '/login',
  name: 'login',
  meta: {
    menuIndex: '3-5'
  },
  component: () => import('../views/Login.vue')
}, { //注册
  path: '/register',
  name: 'register',
  meta: {
    menuIndex: '3-5'
  },
  component: () => import('../views/Register.vue')
}, { //忘记密码
  path: '/password',
  name: 'password',
  meta: {
    menuIndex: '3-5'
  },
  component: () => import('../views/Password.vue')
}, { //新闻资讯
  path: '/setting',
  name: 'setting',
  meta: {
    menuIndex: '1',
  },
  component: () => import('../views/setting.vue')
}, { //帮助中心
  path: '/help',
  name: 'help',
  meta: {
    menuIndex: '3-5',
  },
  component: () => import('../views/help.vue')
}]



const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  console.log(to); //即将去的路由
  console.log(from);//当前要离开的路由
  // sessionStorage.setItem("menuIndex", to.meta.menuIndex)
  //     window.scrollTo(0, 0)
  //     next();
  //     return
  let filterList = ['/supplyDetail2','/demandDetail2']
  let scrollToPageList = ['/supplyDetail','/demandDetail','/appDetail','/solutionDetail','/newsDetail','/noticeInfo','/questions','/help', '/login', '/register', '/password']
  if(filterList.includes(to.path)){
    console.log('包含')
    if(localStorage.getItem('mobile')){
      apiFun.loginIsLogin({mobile: localStorage.getItem('mobile')}).then((res) => {
        if(res.code == 1){
          console.log(res)
          sessionStorage.setItem("menuIndex", to.meta.menuIndex)
          if(scrollToPageList.includes(to.path)){
            window.scrollTo(0, 0)
          }
          next();
        }else{
          next('/login');
        }
      })
    } else {
      // 不存在
      next('/login');
    }
  } else {
    console.log('不包含')
    sessionStorage.setItem("menuIndex", to.meta.menuIndex)
    if(scrollToPageList.includes(to.path)){
      window.scrollTo(0, 0)
    } 
    // window.scrollTo(0, 0)
    next();
  }
  console.log('e')
})

export default router
