<template>
  <div class="headbox">
    <div class="headtop">
      <img src="../assets/img/logo.jpg" class="logo-icon" />
      <div style="width: 350px;margin-left: 20px;">
        <el-input v-model="searchValue" placeholder="请输入搜索内容" class="head-search"
          input-style="height: 36px;width: 192px;font-size: 16px;" @keypress.enter="search">
          <template #prepend>
            <el-popover placement="bottom-start" trigger="click" effect="light" width="247px"
              popper-style="padding: 12px 12px 7px 12px;" :visible="searchTypePopover">
              <template #reference>
                <div class="search-left pointer" @click="onClickOutside">
                  <div class="text">{{ searchType }}</div>
                  <el-icon :size="14" color="#BCC1CB" class="icon">
                    <ArrowDownBold />
                  </el-icon>
                </div>
              </template>
              <div class="search-type-list">
                <div class="pointer" v-for="(item, index) in searchTypeList" :key="'searchTypeList' + index"
                  :style="(index + 1) % 3 == 0 ? '' : 'margin-right: 14px;'" @click="selectSearchType(item)">{{ item.name
                  }}</div>
              </div>
            </el-popover>
          </template>
          <template #suffix>
            <el-icon class="pointer" :size="20" color="#BCC1CB" @click="search">
              <Search />
            </el-icon>
          </template>
        </el-input>

      </div>
      <div class="tabs">
        <div class="tab pointer" @click="btnClick(1)">产品发布</div>
        <div class="tab pointer" @click="btnClick(2)">产能需求</div>
        <div v-if="!mobile" class="tab pointer" @click="btnClick(3)">登录</div>
        <div v-if="!mobile" class="tab pointer" @click="btnClick(4)">注册</div>
        <div class="tab pointer" v-if="mobile" @click="btnClick(5)">{{ mobile }}</div>
      </div>
    </div>
    <el-menu :default-active="activeIndex" class="el-menu-demo head_menu" mode="horizontal" background-color="#FFF"
      text-color="#666B8A" active-text-color="#157AED" :ellipsis='false' :collapse="false">
      <el-menu-item index="1" style="width: 14.29%;" @click="jumpPage('/home', '1')">
        <div>首页</div>
      </el-menu-item>
      <el-menu-item index="2" style="width: 14.29%;" @click="jumpPage('/news', '2')">
        <div>新闻资讯</div>
      </el-menu-item>
      <el-sub-menu index="3" :popper-offset='0' popper-class="clbun" style="width: 14.29%;" :show-timeout="100"
         @click="jumpPage('/capacitySharing', '3')">
        <template #title>
          <div>产能共享</div>
        </template>
        <el-menu-item index="3-1" style="background: #FFF !important;" @click="jumpPage('/supplyList', '3-1', 1)">
          <div>供应</div>
        </el-menu-item>
        <div class="wieth"></div>
        <el-menu-item index="3-2" style="background: #FFF !important;" @click="jumpPage('/demandList', '3-2', 1)">
          <div>需求</div>
        </el-menu-item>
        <!-- <div class="wieth"></div>
        <el-menu-item index="3-3" style="background: #FFF !important;" @click="jumpPage('/capacitySharingList', '3-5', '生产能力')">
          <div>生产能力</div>
        </el-menu-item>
        <div class="wieth"></div>
        <el-menu-item index="3-4" style="background: #FFF !important;" @click="jumpPage('/capacitySharingList', '3-5', '综合服务')">
          <div>综合服务</div>
        </el-menu-item> -->
        <el-menu-item index="3-5" style="background: #FFF !important;display: none;">
          <div>产能共享</div>
        </el-menu-item>
      </el-sub-menu>
      <el-menu-item index="4" style="width: 14.29%;" @click="jumpPage('/appStore', '4')">
        <div>应用商城</div>
      </el-menu-item>
      <el-menu-item index="5" style="width: 14.29%;" @click="jumpPage('/solution', '5')">
        <div>解决方案</div>
      </el-menu-item>
      <el-menu-item index="6" style="width: 14.29%;" @click="jumpPage('IOT', '6')">
        <div>IOT平台</div>
      </el-menu-item>
      <el-menu-item index="7" style="width: 14.29%;" @click="jumpPage('/aboutUs', '7')">
        <div>关于我们</div>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data () {
    return {
      searchValue: '',//搜索框内容
      searchType: '请选择',
      searchTypeId: 0,
      searchTypeList: [{
        id:1,
        name: '资讯',
      }, {
        id:2,
        name: '供应',
      }, {
        id:3,
        name: '需求',
      }, {
        id:4,
        name: '应用商城',
      }, {
        id:5,
        name: '解决方案',
      }],
      searchTypePopover: false,
      activeIndex: '1',
      mobile: ''
    }
  },
  mounted () {
    // setTimeout(() => {
    //   this.activeIndex = sessionStorage.getItem('menuIndex')
    // }, 50);
  },
  created () {
    if (localStorage.getItem('mobile')) {
      this.$apiFun.loginIsLogin({mobile: localStorage.getItem('mobile')}).then((res) => {
        if (res.code == 1) {
          this.mobile = localStorage.getItem('mobile')
        } else {
          this.mobile = ''
        }
      })
    }
  },
  updated () {

  },
  watch: {
    $route(to, from){
      console.log(to)
      for (let i = 0; i < document.getElementsByClassName('el-menu-item').length; i++) {
        document.getElementsByClassName('el-menu-item')[i].blur()
      }
      this.activeIndex = to.meta.menuIndex
      sessionStorage.setItem('menuIndex', to.meta.menuIndex)
      setTimeout(() => {
        console.log(this.activeIndex)
      }, 3000);
    },
  },
  methods: {
    // 请选择点击事件
    onClickOutside () {
      this.searchTypePopover = !this.searchTypePopover
    },
    // 请选择里的类型点击事件
    selectSearchType (item) {
      this.searchType = item.name
      this.searchTypeId = item.id
      this.searchTypePopover = false
    },
    //搜索跳转
    search () {
      if (!this.searchValue) {
        this.$message({
          showClose: true,
          message: '请先输入搜索内容',
          type: 'warning'
        });
        return
      }
      if (!this.searchTypeId) {
        this.$message({
          showClose: true,
          message: '请选择要搜索的服务',
          type: 'warning'
        });
        return
      }
      console.log(this.searchTypeId)
      console.log(this.searchValue)
      if(this.searchTypeId == 1){
        // 新闻资讯
        this.$router.push({ path: '/news', query: {keywords: this.searchValue} });
      } else if(this.searchTypeId == 2){
        // 供应
        this.$router.push({ path: '/supplyList', query: {keywords: this.searchValue} });
      } else if(this.searchTypeId == 3){
        // 需求
        this.$router.push({ path: '/demandList', query: {keywords: this.searchValue} });
      } else if(this.searchTypeId == 4){
        // 应用商城
        this.$router.push({ path: '/appStoreList', query: {keywords: this.searchValue} });
      } else if(this.searchTypeId == 5){
        // 解决方案
        this.$router.push({ path: '/solution', query: {keywords: this.searchValue} });
      }
    },
    // tab点击
    jumpPage (path, index, type) {
      if(type == 1){
        let Detail= this.$router.resolve({
          path:path,
        })
        window.open(Detail.href,'_blank')
      } else {
        this.$router.push({ path: path });
      }
    },
    changeTabsFun(index){
      let active = this.activeIndex
      setTimeout(() => {
        console.log('changeTabsFun')
        console.log(this.activeIndex,index)
        console.log($(".head_menu").children().removeClass("is-active"))
        for (let i = 0; i < $(".head_menu").children().length; i++) {
          $(".head_menu").children()[i].blur()
          if(i == 2){
            $($($(".head_menu").children()[i]).find(".el-tooltip__trigger")[0]).css("color","rgb(102, 107, 138)")
            $($($(".head_menu").children()[i]).find(".el-tooltip__trigger")[0]).css("border-bottom-color","transparent")
          }
        }
        if(this.$router.options.history.location == '/' || this.$router.options.history.location == '/home'){
          $($(".head_menu").children()[0]).addClass('is-active')
        } else if (this.$router.options.history.location == '/news'){
          $($(".head_menu").children()[1]).addClass('is-active')
        } else if (this.$router.options.history.location == '/capacitySharing' || 
          this.$router.options.history.location == '/supplyList' || 
          this.$router.options.history.location == '/demandList'){
          $($(".head_menu").children()[2]).addClass('is-active')
          $($($(".head_menu").children()[2]).find(".el-tooltip__trigger")[0]).css("color","rgb(21, 122, 237)")
          $($($(".head_menu").children()[2]).find(".el-tooltip__trigger")[0]).css("border-bottom-color","rgb(21, 122, 237)")
        } else if (this.$router.options.history.location == '/appStore'){
          $($(".head_menu").children()[3]).addClass('is-active')
        } else if (this.$router.options.history.location == '/solution'){
          $($(".head_menu").children()[4]).addClass('is-active')
        } else if (this.$router.options.history.location == '/IOT'){
          $($(".head_menu").children()[5]).addClass('is-active')
        } else if (this.$router.options.history.location == '/aboutUs'){
          $($(".head_menu").children()[6]).addClass('is-active')
        }
      }, 1000);
      
    },
    // 顶部按钮点击事件
    btnClick (type) {
      if (type == 1) {
        // 产品发布
        console.log("产品发布")
        if (localStorage.getItem('mobile')) {
          this.$apiFun.loginIsLogin({mobile: localStorage.getItem('mobile')}).then((res) => {
            if (res.code == 1) {
              window.location.href = 'https://yzh.yuanliancloud.com/admin#/admin/company.companysupply/index.html'
            } else {
              this.$router.push({ path: '/login' });
            }
          })
        } else {
          this.$router.push({ path: '/login' });
        }
      } else if (type == 2) {
        // 产能需求
        console.log("产能需求")
        if (localStorage.getItem('mobile')) {
          this.$apiFun.loginIsLogin({mobile: localStorage.getItem('mobile')}).then((res) => {
            if (res.code == 1) {
              window.location.href = 'https://yzh.yuanliancloud.com/admin#/admin/company.companyneed/index.html'
            } else {
              this.$router.push({ path: '/login' });
            }
          })
        } else {
          this.$router.push({ path: '/login' });
        }
      } else if (type == 3) {
        // 登录
        console.log("登录")
        this.$router.push({ path: '/login' });
      } else if (type == 4) {
        // 注册
        console.log("注册")
        this.$router.push({ path: '/register' });
      } else {
        if (localStorage.getItem('mobile')) {
          this.$apiFun.loginIsLogin({mobile: localStorage.getItem('mobile')}).then((res) => {
            if (res.code == 1) {
              window.location.href = 'https://yzh.yuanliancloud.com/admin'
            } else {
              this.mobile = ''
              this.$router.push({ path: '/login' });
            }
          })
        }
        
      }
      // window.location.href = 'https://yzh.yuanliancloud.com/admin'
      // if(sessionStorage.getItem('uuid')){
      //   // 调接口传uuid，判断是否登录，如果登录则直接跳后台，如果未登录

      //   window.location.href = 'https://yzh.yuanliancloud.com/admin'
      // } else {
      //   let code = uuidv4();
      //   window.location.href = 'https://yzh.yuanliancloud.com/admin/login/index?code=' + code
      //   sessionStorage.setItem('uuid', code)
      // }
    }
  }
}
</script>
<style scoped lang="scss">
.headbox {
  width: 100%;
  min-width: 1200px;
  margin: auto;
  background-color: #fff;
}

.headtop {
  width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 70px;

  .logo-icon {
    height: 45px;
    object-fit: contain;
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    margin-left: 35px;

    .tab {
      height: 100%;
      line-height: 70px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666B8A;
      padding: 0 15px;
    }

    .tab:hover {
      color: #FFF;
      background-color: #157AED;
    }
  }

  .head-search {
    .search-left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 109px;

      .text {
        width: 100%;
        text-align: center;
        font-size: 16px;
        color: #666B8A;
      }

      .icon {
        margin-right: 10px;
      }
    }

  }
}

.search-type-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  div {
    border: 1px #e0e0e0 solid;
    color: #333;
    font-size: 13px;
    font-family: PingFangSC-Regular;
    width: 71px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 5px;
    background-color: #f2f2f2;
  }

  .pointer:hover {
    color: #157AED;
  }
}

.el-menu-demo {
  width: 1200px;
  margin: 0 auto;
  max-width: 1920px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
<style>
.headbox .head-search .el-input-group__prepend {
  background-color: #FFF !important;
  padding: 0 0;
}

.headbox .el-menu--horizontal>.el-menu-item.is-active {
  background: linear-gradient(180deg, #FFF 0%, #C0D6F4 100%) !important;
}

.headbox .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background: linear-gradient(180deg, #FFF 0%, #C0D6F4 100%) !important;
}

.headbox .el-menu--horizontal>.el-menu-item {
  /* border-bottom: none !important; */
  font-size: 18px !important;
}

.headbox .el-menu--horizontal>.el-menu-item.is-active {
  /* border-bottom: none !important; */
}

.headbox .el-menu--horizontal {
  border-bottom: none !important;
}

.headbox .el-menu--horizontal>.el-sub-menu.is-active .el-sub-menu__title {
  /* border-bottom: none !important; */
}

.headbox .el-menu--horizontal>.el-sub-menu .el-sub-menu__title {
  /* border-bottom: none !important; */
  font-size: 18px !important;
}

.headbox .el-menu--horizontal .el-sub-menu:hover {
  background: linear-gradient(180deg, #FFF 0%, #C0D6F4 100%) !important;
}

.headbox .el-menu--horizontal .el-sub-menu__title:hover {
  background: linear-gradient(180deg, #FFF 0%, #C0D6F4 100%) !important;
}

.headbox .el-menu--horizontal>.el-sub-menu.is-active>.el-sub-menu__title {
  background: linear-gradient(180deg, #FFF 0%, #C0D6F4 100%) !important;
}

.headbox .el-menu--horizontal>.el-sub-menu>.el-sub-menu__title {
  justify-content: center;
}

.headbox .el-menu--horizontal>.el-sub-menu>.el-sub-menu__title .el-icon {
  font-size: 18px !important;
  margin-top: 2px !important;
}

.clbun {
  width: 171px !important;
}

.clbun .el-menu--popup {
  min-width: 171px !important;
}

.clbun .el-menu--popup .el-menu-item {
  justify-content: center;
  font-size: 18px !important;
}

.clbun .el-menu--popup .el-menu-item:hover {

  color: #157AED !important;
}</style>
