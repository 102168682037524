import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './assets/font/font.css'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as echarts from 'echarts'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import apiFun from "./api/api.js";
import globalMethod from './components/globalMethod.js'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

//配置安全密钥
window._AMapSecurityConfig = {
	securityJsCode: '你的安全密钥' //*  安全密钥
}
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(store)
app.use(router)
app.use(ElementPlus,{locale})
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.$apiFun = apiFun;
app.config.globalProperties.$globalMethod = globalMethod;
app.mount('#app')

