<template>
  <div id="app">
    <my-Header v-if="$route.path != '/login' && $route.path != '/register' && $route.path != '/password'"></my-Header>
    <router-view v-slot="{ Component }">
      <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive"/>
      <keep-alive>
        <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive"/>
      </keep-alive>
    </router-view>
    <my-Footer v-if="$route.path != '/login' && $route.path != '/register' && $route.path != '/password'"></my-Footer>
    <el-backtop :right="100" :bottom="100" />
    <!-- <div v-if="$route.path == '/login' || $route.path == '/register' || $route.path == '/password'">
      <router-view></router-view>
    </div> -->
  </div>
</template>
<script>
import myHeader from './components/header.vue'
import myFooter from './components/footer.vue'

export default {
  name: 'app',
  components: {
    myHeader,
    myFooter,
  },
  data () {
    return {
      login: '',
      register: false,
      forget: false,
      screen: 0,
    }
  },
  created () {
  },
  mounted () {
  },
}
</script>
<style>
.image-slot{
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-slot video{
  width: 100px;
  height: 100px;
}
p{
  margin: 0;
}
pre{
  margin: 0;
}
.pointer {
  cursor: pointer;
}

#app,
html,
body {
  margin: 0 auto;
  padding: 0;
}
html,body{
  width: 100%;
}
#app{
  min-width: 1200px;
  max-width: 1920px;
  margin: 0 auto;
}
.router-link-active {
  text-decoration: none;
  color: #fff;
}

.router-link,
a {
  text-decoration: none;
  color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.stay span {
  color: #1890FF;
  margin-left: 5px;
}

.stay {
  width: 1200px;
  margin: auto;
  margin-bottom: 17px;
  color: #333333;
  font-size: 12px;
}

input,
textarea {
  outline: none;
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.hvr-float {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-float:hover, .hvr-float:focus, .hvr-float:active {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}
.hvr-fade {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  overflow: hidden;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
.hvr-fade:hover, .hvr-fade:focus, .hvr-fade:active {
  background-color: #157AED;
  color: #FFF !important;
}
</style>
