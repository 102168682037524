//引入刚才的http.js文件
import https from './request.js';
	let apiFun = {};
  // 首页公告
  apiFun.indexNoticeList = function(params) {
		return https.get('api/index/noticeList', params)
	}
  // 首页解决方案
  apiFun.indexIndexPlan = function(params) {
		return https.get('api/index/indexPlan', params)
	}
  // 首页应用商城
  apiFun.indexIndexApplication = function(params) {
		return https.get('api/index/indexApplication', params)
	}
  // 轮播图
  apiFun.indexBannerList = function(params) {
		return https.get('api/index/bannerList', params)
	}
  // 资讯列表
  apiFun.indexMessageList = function(params) {
		return https.get('api/index/messageList', params)
	}
  // 资讯详情
  apiFun.indexMessageInfo = function(params) {
		return https.get('api/index/messageInfo', params)
	}
  // 公告详情
  apiFun.indexNoticeInfo = function(params) {
		return https.get('api/index/noticeInfo', params)
	}
  // 资讯分类
  apiFun.indexMessageCate = function(params) {
		return https.get('api/index/messageCate', params)
	}
  // 首页供需
  apiFun.indexIndexSupplyNeed = function(params) {
    return https.get('api/index/indexSupplyNeed', params)
	}
  // 供应列表
  apiFun.indexSupplyList = function(params) {
    return https.get('api/index/supplyList', params)
	}
  // 供应详情
  apiFun.indexSupplyInfo = function(params) {
    return https.get('api/index/supplyInfo', params)
	}
  // 需求列表
  apiFun.indexNeedList = function(params) {
    return https.get('api/index/needList', params)
	}
  // 需求详情
  apiFun.indexNeedInfo = function(params) {
    return https.get('api/index/needInfo', params)
	}
  // 行业分类
  apiFun.indexIndustryCate = function(params) {
    return https.get('api/index/industryCate', params)
	}
  // 服务领域列表
  apiFun.indexServicesList = function(params) {
    return https.get('api/index/servicesList', params)
	}
  // 城市列表
  apiFun.indexCityList = function(params) {
    return https.get('api/index/cityList', params)
	}
  // 应用列表
  apiFun.indexApplicationList = function(params) {
    return https.get('api/index/ApplicationList', params)
	}
  // 应用版块分类应用
  apiFun.indexCateApplicationList = function(params) {
    return https.get('api/index/cateApplicationList', params)
	}
  // 应用详情
  apiFun.indexApplicationInfo = function(params) {
    return https.get('api/index/applicationInfo', params)
	}
  // 方案列表分类
  apiFun.indexPlanCate = function(params) {
    return https.get('api/index/planCate', params)
	}
  // 方案列表
  apiFun.indexPlanList = function(params) {
    return https.get('api/index/planList', params)
	}
  // 方案详情
  apiFun.indexPlanInfo = function(params) {
    return https.get('api/index/planInfo', params)
	}
  // 在线、离线设备数量
  apiFun.indexDeviceList = function(params) {
    return https.get('api/index/deviceList', params)
	}
  // 发布数量、供应商数量统计
  apiFun.indexCounting = function(params) {
    return https.get('api/index/counting', params)
	}
  // 产能共享页面分类供应
  apiFun.indexSupplyCate = function(params) {
    return https.get('api/index/supplyCate', params)
	}
  // 网站配置信息
  apiFun.indexCommonConfig = function(params) {
    return https.get('api/index/commonConfig', params)
	}
  // 登录
  apiFun.indexLogin = function(params) {
    return https.get('admin/login/companylogin', params)
	}
  // 登录
  // apiFun.indexLogin = function(params) {
  //   return https.post('api/index/login', params)
	// }
  // 图形验证码
  apiFun.indexCaptcha = function(params) {
    return https.get('admin/login/captcha', params)
	}
  // 发送验证码
  apiFun.indexGetVerifyCode = function(params) {
    return https.get('api/index/getVerifyCode', params)
	}
  // 注册
  apiFun.indexRegister = function(params) {
    return https.post('admin/login/register', params)
	}
  // 地图设备在线离线数量
  apiFun.indexDeviceSum = function(params) {
    return https.get('api/index/deviceSum', params)
	}
  // 找回密码
  apiFun.indexForgetPassword = function(params) {
    return https.get('api/index/forgetPassword', params)
	}
  // 判断是否登录
  apiFun.loginIsLogin = function(params) {
    return https.get('admin/login/isLogin', params)
	}
  // 问题列表
  apiFun.indexQuestionList = function(params) {
    return https.get('api/index/questionList', params)
	}
  // 问题详情
  apiFun.indexQuestionInfo = function(params) {
    return https.get('api/index/questionInfo', params)
	}
  // 帮助中心列表
  apiFun.indexHelpList = function(params) {
    return https.get('api/index/helpList', params)
	}
  // 供应提交询价
  apiFun.indexCommitInquiry = function(params) {
    return https.post('api/index/commitInquiry', params)
	}
  // 需求提交报价
  apiFun.indexCommitOffer = function(params) {
    return https.post('api/index/commitOffer', params)
	}
	// 应用提交询价
  apiFun.indexCommitEnquiry = function(params) {
    return https.post('api/index/commitEnquiry', params)
	}

  // 设备总数
  apiFun.indexAllDeviceSum = function(params) {
    return https.get('api/index/allDeviceSum', params)
	}

  // 随机生成11位数
  apiFun.indexCreateNumber = function(params) {
    return https.get('api/index/createNumber', params)
	}

	export default apiFun;

